import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactMarkdown from 'react-markdown';

const Markdown = ({ content }) => {
  const renderers = {
    code: ({ language, value }) => (
      <SyntaxHighlighter
        style={atomDark}
        language={language}
        children={value}
      />
    ),
  };

  return <ReactMarkdown renderers={renderers} children={content} />;
};

export default Markdown;
