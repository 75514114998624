import { graphql } from 'gatsby';
import React from 'react';
import { format } from 'date-fns';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Container from '../styles/Container';
import SEO from '../components/SEO';
import CategoryList from '../components/CategoryList';
import Markdown from '../components/Markdown';
import ContentStyles from '../styles/ContentStyles';

export const query = graphql`
  query($slug: String!) {
    post: sanityPost(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      title
      publishedAt
      categories {
        id
        title
        colorHex
      }
      mainImage {
        asset {
          fluid(maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
      body
      excerpt
    }
  }
`;

const PostImage = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    height: 400px;
  }
`;

const PostHeading = styled.div`
  margin: 4rem 0;
  time {
    font-family: var(--fontSecondary);
    opacity: 0.7;
  }
  h1 {
    margin: 0 0 2rem;
    font-family: var(--fontSecondary);
  }

  @media (max-width: 767.98px) {
    h1 {
      font-size: 2.5rem;
    }
  }
`;

const PostBody = styled.div`
  margin: 0 0 10rem;
  pre {
    margin: 2rem 0;
  }
`;

const Post = ({ data }) => (
  <>
    <SEO title={data.post.title} description={data.post.excerpt} />
    <PostImage>
      <Img fluid={data.post.mainImage?.asset?.fluid} alt={data.post.title} />
    </PostImage>
    <Container>
      <PostHeading>
        <time>{format(new Date(data.post.publishedAt), 'MMMM do, yyyy')}</time>
        <h1>{data.post.title}</h1>
        <CategoryList categories={data.post.categories} />
      </PostHeading>
      <PostBody>
        <ContentStyles>
          <Markdown content={data.post.body} />
        </ContentStyles>
      </PostBody>
    </Container>
  </>
);

export default Post;
