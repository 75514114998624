import styled from 'styled-components';

const ContentStyles = styled.div`
  a {
    color: var(--black);
    text-decoration: underline;
  }
`;

export default ContentStyles;
